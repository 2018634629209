import styled from 'styled-components';

const NiceLinkStyles = styled.a`
  display: inline-block;
	color: #000;
	text-decoration: none;
	padding: 20px 0;
	position: relative;
	
	&:hover {
		.button-stroke:nth-child(2) {
			stroke-dashoffset: 0;
		}
	}

  .button-stroke {
    display: block;
    width: 100%;
    max-width: 150px;
    height: 20px;
    stroke: #48a776;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    stroke-width: 4;
    
    &:nth-child(2) {
      stroke-dasharray: 650px;
      stroke-dashoffset: 650px;
      stroke: black;
      stroke-width: 5;
      transition: stroke-dashoffset 600ms ease-out;
    }
  }
`;

export default NiceLinkStyles;