import React from 'react';

import NiceLinkWrapper from './NiceLinkStyles';

const NiceLink = ({ href, title, text }) => (
  <NiceLinkWrapper 
    href={href}
    title={title}
  >
    {text}
    <svg className="button-stroke" viewBox="0 0 154 13">
			<path d="M2 2c49.7 2.6 100 3.1 150 1.7-46.5 2-93 4.4-139.2 7.3" fill="none" strokeLinecap="round" strokeLinejoin="round" vectorEffect="non-scaling-stroke"/>
		</svg>
    <svg className="button-stroke" viewBox="0 0 154 13">
			<path d="M2 2c49.7 2.6 100 3.1 150 1.7-46.5 2-93 4.4-139.2 7.3" fill="none" strokeLinecap="round" strokeLinejoin="round" vectorEffect="non-scaling-stroke"/>
		</svg>
  </NiceLinkWrapper>
);

export default NiceLink;