import React from "react"

import Layout from "../components/layout"
import BookFragment from "../components/BookFragment"
import AboutUs from "../components/AboutUs"
import Opinions from "../components/Opinions"
import DownloadBook from "../components/DownloadBook"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Do góry nogami" />
    <BookFragment />
    <AboutUs />
    <Opinions />
    <DownloadBook />
  </Layout>
)

export default IndexPage
