import React from 'react';

import BookFragmentWrapper from './styles/BookFragmentStyles';

import bookFragmentImage from '../images/chapter-one.png';

const BookFragment = () => (
  <BookFragmentWrapper>
    <div id="book-fragment">
      <h2>Pierwszy rozdział</h2>
      <p>
        Zalana łzami Antosia wpadła do domu, trzaskając drzwiami.
      </p>
      <p>
        – Mamo, pan Stanisław sprzedaje Kacpra! – krzyknęła rozpaczliwie i pobiegła prosto do
        swojego pokoju.
      </p>
      <p>
        Zaskoczona reakcją córki mama oderwała wzrok od ekranu laptopa i spojrzała pytająco na
        wchodzącego do salonu męża. Ten spokojnie pocałował ją na powitanie, a później usiadł obok
        układającego klocki Karola, starszego brata Antosi.
      </p>
      <p>
        – Pamiętasz, jak mówiłem ci, że Stanisław jest chory i będzie musiał na jakiś czas zamknąć
        stadninę? Podobno znalazł się gospodarz, który chce kupić ulubionego konia Tosi i w przyszłym tygodniu ma go zabrać.
      </p>
      <p>
        – Mała uwielbia Kacpra! Musi jej być bardzo przykro! Pójdę na górę i porozmawiam z nią, a ty pobaw się z Karolem – zaproponowała mama.
      </p>
      <img src={bookFragmentImage} alt="Tosia i Mama" />
    </div>
  </BookFragmentWrapper>
);

export default BookFragment;

/*
<h3>2.</h3>
      <p>
        Tosia schowała się w tajnej bazie zrobionej z koca i krzeseł. Słychać było, że nadal płacze.
      </p>
      <p>
      – Nie chcę, żeby ktoś zabrał mojego ukochanego konika! Nie chcę! – wyszlochała
      zrozpaczonym głosem.
      </p>
      <p>
      – Antosiu, pan Stanisław jest chory i nie da rady zajmować się wszystkimi zwierzętami. –
      Mama na czworakach weszła do bazy i usiadła obok córki.
      </p>
      <p>
      – A może moglibyśmy zabrać Kacpra do siebie? – zapytała Tosia.
      </p>
      <p>
      – Skarbie, to nie takie proste. Nie mamy odpowiedniego miejsca, w którym mógłby mieszkać
      koń. Wiem, że jest ci bardzo przykro, ale nie możemy go zabrać. Wpadłam jednak na pewien pomysł! Może wykupimy dodatkową lekcję jazdy konnej, żebyś jutro mogła pożegnać się z Kacprem? Co ty na to? – zaproponowała mama, mocno przytulając córkę.
      </p>
      <p>
      – Naprawdę? – Antosia nieco się rozchmurzyła. – Dziękuję, że będę mogła zobaczyć Kacpra
      jeszcze jeden raz. To mój ukochany konik.
      </p>
*/