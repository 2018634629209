import styled from 'styled-components';

const AboutUsWrapper = styled.div`
  margin-bottom: 50px;
  
  .author {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    
    h3 {
      margin-bottom: .7em;
    }

    .text {
      max-width: 70%;
    }

    img {
      width: 160px;
      max-width: 160px;
      margin: 0;
    }

        @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;

      .text {
        max-width: 100%;
      }

      .image {
        margin-bottom: .5em;
      }

      img {
        width: 300px;
        max-width: 100%;
      }
    }
  }
`;

export default AboutUsWrapper;