import React from 'react';

import NiceLink from './shared/NiceLink';
import OpinionsWrapper from './styles/OpinionsStyles';

const Opinions = () => (
  <OpinionsWrapper>
    <div id="opinions">
      <h2>Opinie</h2>
      <div className="review">
        <h3 className="review-author">
          <NiceLink href="https://karolinalubas.pl" text="Karolina" />
        </h3>
        <p className="review-text">
          "Do góry nogami" to bardzo pouczająca i ciepła historia poruszająca tematykę świadomego i odpowiedzialnego nie tyle podróżowania, co wykorzystywania do tego celu zwierząt. Nie od dziś wiadomo, że konie w Zakopanem mają ciężki żywot, ponieważ wielu turystów decyduje się wsiąść do bryczki, zamiast osiągnąć cel o własnych siłach. Kasia w swoim ebooku postanowiła w prosty sposób pokazać jak to wygląda oczami... konia.Jest to bardzo mądra historia, która rozczuli serce nawet dorosłego czytelnika i uwrażliwi całą rodzinę na los zwierząt wykorzystywanych do przemieszczania się.
        </p>
      </div>
      <div className="review">
        <h3 className="review-author">
          <NiceLink href="http://www.sylviavoyages.com/" text="Sylwia" />
        </h3>
        <p className="review-text">
          Piękna, mądra i wzruszająca bajka, którą powinny poznać nie tylko dzieci, ale przede wszystkim dorośli!
        </p>
      </div>
      <div className="review">
        <h3 className="review-author">
          <NiceLink href="https://aleksandramakulska.pl/" text="Aleks" />
        </h3>
        <p className="review-text">
        "Do góry nogami" to bajka, którą rodzice powinni czytać dzieciom, dziadkowie wnukom i nauczyciele uczniom. To bardzo ważna historia, która pokazuje, że zwierzętom należy się miłość i szacunek. Pięknie napisana i cudownie zilustrowana wzrusza i zostaje w głowie. Puszczajmy ją w świat!
        </p>
      </div>
    </div>
  </OpinionsWrapper>
)

export default Opinions;
