import styled from 'styled-components';

const DownloadBookWrapper = styled.div`
    margin: 150px 0 75px 0;
    text-align: center;

    a {
      box-sizing:border-box;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 28px;
      padding: 15px 20px;
      color: #000;
      text-decoration:none;
      border: 4px solid #48a776;
      border-bottom-left-radius: 185px 25px;
      border-bottom-right-radius: 20px 205px;
      border-top-left-radius: 125px 25px;
      border-top-right-radius: 10px 205px;
      transition: padding 100ms ease-in-out;
    }

    a:hover {
      padding: 20px 25px;
    }
`;

export default DownloadBookWrapper;