import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import DownloadBookWrapper from './styles/DownloadBookStyles';

const DownloadBook = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <DownloadBookWrapper>
      <div id="download-book">
        <a 
          href={data.allFile.edges[0].node.publicURL} 
          title="Pobierz książkę w pdf" 
          onClick={(e) => {
            trackCustomEvent({
              category: "Book Download",
              action: "Click",
            });
          }}
        >
          Pobierz książkę
        </a>
      </div>
    </DownloadBookWrapper>
  );
};

export default DownloadBook;
