import styled from 'styled-components';

const OpinionsWrapper = styled.div`
    margin-bottom: 75px;

    .review-text {
      font-style: italic;
    }
`;

export default OpinionsWrapper;