import React from 'react';

import AboutUsWrapper from './styles/AboutUsStyles';

import NiceLink from './shared/NiceLink';

import avatar1 from '../images/kasia.png';
import avatar2 from '../images/aga.png';

const AboutUs = () => (
  <AboutUsWrapper>
    <div id="about-us">
      <h2>O nas</h2>
      <div className="author">
        <div className="image">
          <img src={avatar1} alt="Kasia" />
        </div>
        <div className="text">
          <h3>
            <NiceLink
              text="Kasia - tekst"
              href="https://www.instagram.com/imielscy_w_podrozy/"
              title="imielscywpodrozy.pl"
            />
          </h3>
          <p>Zakochana w podróżach nauczycielka języka angielskiego. Na codzień pracuje w szkole podstawowej, a po godzinach pisze bloga, planuje i realizuje kolejne małe i duże podróże. Jakiś czas temu wzięła udział w „Klubie Otwartej Szuflady”, który zmotywował ją do tego, aby puścić w świat swoją pierwszą bajkę.</p>
        </div>
      </div>
      <div className="author">
        <div className="image">
          <img src={avatar2} alt="Aga" />
        </div>
        <div className="text">
        <h3>
            <NiceLink
              text="Aga - ilustracje"
              href="https://instagram.com/pani_agnieszka_wu"
              title="Instagram Agnieszki"
            />
          </h3>
          <p>Polonistka mająca przyjemność pracować z młodymi ludźmi o niecodziennej wyobraźni i wiedzy o życiu. Dumna wychowawczyni dzielnego oddziału „B”. W wolnych chwilach zajmuje kuchenny stół wraz z akwarelami, markerami, kredkami i kolejnymi szkicownikami, a w wersji przyjaznej przestrzeni – sięga po tablet. Z natury wieczna studentka, zatem z radością odkrywa i bawi się wszelkimi technikami wyrazu artystycznego. Mieszka w Nowej Hucie.</p>
        </div>
      </div>
    </div>
  </AboutUsWrapper>
);

export default AboutUs;
